import { useEffect, useState, useMemo } from 'react';
import { useMeeting } from '../meeting/meetingProviderContextDef';
import { eventEmitter, events } from '../utils';

/**
 *
 * @param {string} participantId
 * @param {{
 *  onStreamDisabled?: Function,
 *  onStreamEnabled?: Function,
 *  onMediaStatusChanged?: Function,
 *  onVideoQualityChanged?: Function,
 * }} options
 *
 * @returns {{
 *   displayName: string;
 *   participant: Participant;
 *   webcamStream: Stream;
 *   micStream: Stream;
 *   screenShareStream: Stream;
 *   screenShareAudioStream: Stream;
 *   webcamOn: boolean;
 *   micOn: boolean;
 *   screenShareOn: boolean;
 *   isLocal: boolean;
 *   isActiveSpeaker: boolean;
 *   isMainParticipant: boolean;
 *   pinState: any;
 *   mode: "CONFERENCE" | "VIEWER";
 *   metaData: object;
 *   consumeMicStreams: () => void;
 *   consumeWebcamStreams: () => void;
 *   stopConsumingMicStreams: () => void;
 *   stopConsumingWebcamStreams: () => void;
 *   setQuality: (quality: "low" | "med" | 'high') => void;
 *   setViewPort: (width: number, height: number) => void;
 *   enableMic: () => void;
 *   disableMic: () => void;
 *   enableWebcam: () => void;
 *   disableWebcam: () => void;
 *   remove: () => void;
 *   captureImage: ({height,width}) => Promise<string | null>;
 *   pin: (data: "SHARE_AND_CAM" | "CAM" | "SHARE") => void;
 *   unpin: (data: "SHARE_AND_CAM" | "CAM" | "SHARE") => void;
 *   switchTo: ({ meetingId, payload, token }: {
 *      meetingId: string;
 *      payload: string;
 *      token: string;
 *   }) => Promise<void>;
 *   getAudioStats: () => Promise<[{
 *   bitrate: number,
 *   rtt: number,
 *   network:String,
 *   codec: String,
 *   jitter: number,
 *   limitation: Object,
 *   totalPackets: number,
 *   packetsLost: number,
 *   concealmentEvents: number,
 *   insertedSamplesForDecelaration: number,
 *   removedSamplesForAccelaration: number,
 *   size:Object
 * }]>;
 * getVideoStats: () => Promise<[{
 *   bitrate: number,
 *   rtt: number,
 *   network:String,
 *   codec: String,
 *   jitter: number,
 *   limitation: Object,
 *   totalPackets: number,
 *   packetsLost: number,
 *   concealmentEvents: number,
 *   insertedSamplesForDecelaration: number,
 *   removedSamplesForAccelaration: number,
 *   size:Object,
 *   currentSpatialLayer: number,
 *   currentTemporalLayer: number,
 *   preferredSpatialLayer: number,
 *   preferredTemporalLayer: number
 * }]>;
 * getShareStats: () => Promise<[{
 *   bitrate: number,
 *   rtt: number,
 *   network:String,
 *   codec: String,
 *   jitter: number,
 *   limitation: Object,
 *   totalPackets: number,
 *   packetsLost: number,
 *   concealmentEvents: number,
 *   insertedSamplesForDecelaration: number,
 *   removedSamplesForAccelaration: number,
 *   size:Object,
 *   currentSpatialLayer: number,
 *   currentTemporalLayer: number,
 *   preferredSpatialLayer: number,
 *   preferredTemporalLayer: number
 * }]>;
 *}}
 *
 */
const useParticipant = (
  participantId,
  {
    onStreamEnabled = () => {},
    onStreamDisabled = () => {},
    onMediaStatusChanged = () => {},
    onVideoQualityChanged = () => {}
  } = {}
) => {
  const [webcamStream, setwebcamStream] = useState(null);
  const [micStream, setMicStream] = useState(null);
  const [screenShareStream, setScreenShareStream] = useState(null);
  const [screenShareAudioStream, setScreenShareAudioStream] = useState(null);

  const meeting = useMeeting();

  const {
    participants,
    localParticipant,
    activeSpeakerId,
    mainParticipant,
    pinState
  } = useMemo(() => {
    const participants = meeting?.participants;
    const localParticipant = meeting?.localParticipant;
    const activeSpeakerId = meeting?.activeSpeakerId;
    const mainParticipant = meeting?.mainParticipant;
    const pinState = meeting?.pinnedParticipants?.get(participantId) || {
      cam: false,
      share: false
    };

    // const micOn = meeting?.participants?.get(participantId).micOn || false;
    // const webcamOn = meeting?.participants?.get(participantId).webcamOn || false;

    return {
      participants,
      localParticipant,
      activeSpeakerId,
      mainParticipant,
      pinState
    };
  }, [meeting]);

  /**
   * @type {Participant}
   */
  const participant = participants?.get(participantId);

  const [webcamOn, setWebcamOn] = useState(participant?.webcamOn);
  const [micOn, setMicOn] = useState(participant?.micOn);
  const [mode, setMode] = useState(participant?.mode);

  const setTrack = stream => {
    if (stream.track.readyState === 'live') {
      switch (stream.kind) {
        case 'video':
          if (webcamStream != null) {
            setwebcamStream(null);
          }
          setwebcamStream(stream);
          break;
        case 'audio':
          if (micStream != null) {
            setMicStream(null);
          }
          setMicStream(stream);
          break;
        case 'share':
          if (screenShareStream != null) {
            setScreenShareStream(null);
          }
          setScreenShareStream(stream);
          break;
        case 'shareAudio':
          if (screenShareAudioStream != null) {
            setScreenShareAudioStream(null);
          }
          setScreenShareAudioStream(stream);
          break;
        default:
          break;
      }
    }
  };

  const unSetTrack = stream => {
    switch (stream.kind) {
      case 'video':
        setwebcamStream(null);
        break;
      case 'audio':
        setMicStream(null);
        break;
      case 'share':
        setScreenShareStream(null);
        break;
      case 'shareAudio':
        setScreenShareAudioStream(null);
        break;
      default:
        break;
    }
  };

  const _handleStreamEnabled = stream => {
    setTrack(stream);
    onStreamEnabled(stream);
  };

  const _handleParticipantModeChanged = data => {
    if (participantId === data.participantId) {
      setMode(data.mode);
    }
  };

  const _handleStreamDisabled = stream => {
    unSetTrack(stream);
    onStreamDisabled(stream);
  };

  const _handleVideoQualityChanged = data => {
    const { peerId, prevQuality, currentQuality } = data;
    onVideoQualityChanged({ peerId, prevQuality, currentQuality });
  };

  const _handleMediaStatusChanged = data => {
    const { kind, peerId, newStatus } = data;
    if (kind == 'audio') {
      setMicOn(newStatus);
    } else if (kind == 'video') {
      setWebcamOn(newStatus);
    }
    onMediaStatusChanged({ kind, peerId, newStatus });
  };

  const setQuality = quality => {
    participant?.setQuality(quality);
  };

  const setViewPort = (width, height) => {
    participant?.setViewPort(width, height);
  };

  const enableMic = () => {
    participant?.enableMic();
  };
  const disableMic = () => {
    participant?.disableMic();
  };
  const enableWebcam = () => {
    participant?.enableWebcam();
  };
  const disableWebcam = () => {
    participant?.disableWebcam();
  };
  const pin = data => {
    participant?.pin(data);
  };
  const unpin = data => {
    participant?.unpin(data);
  };
  const remove = () => {
    participant?.remove();
  };
  const captureImage = async ({ height, width } = {}) => {
    const base64Data = await participant?.captureImage({ height, width });
    return base64Data;
  };
  const getAudioStats = async () => {
    return participant?.getAudioStats();
  };
  const getVideoStats = async () => {
    return participant?.getVideoStats();
  };
  const getShareStats = async () => {
    return participant?.getShareStats();
  };

  const consumeWebcamStreams = () => {
    participant?.consumeWebcamStreams();
  };
  const consumeMicStreams = () => {
    participant?.consumeMicStreams();
  };
  const stopConsumingWebcamStreams = () => {
    participant?.stopConsumingWebcamStreams();
  };
  const stopConsumingMicStreams = () => {
    participant?.stopConsumingMicStreams();
  };
  const switchTo = async data => {
    await participant?.switchTo(data);
  };

  useEffect(() => {
    const streams = participant?.streams;

    if (streams) {
      streams.forEach(stream => {
        setTrack(stream);
      });
    }

    if (participant?.micOn) {
      setMicOn(micOn);
    }

    if (participant?.webcamOn) {
      setWebcamOn(webcamOn);
    }

    participant?.on('stream-enabled', _handleStreamEnabled);

    participant?.on('stream-disabled', _handleStreamDisabled);

    participant?.on('media-status-changed', _handleMediaStatusChanged);

    participant?.on('video-quality-changed', _handleVideoQualityChanged);

    eventEmitter.on(
      events['participant-mode-changed'],
      _handleParticipantModeChanged
    );

    return () => {
      participant?.off('stream-enabled', _handleStreamEnabled);

      participant?.off('stream-disabled', _handleStreamDisabled);

      participant?.off('media-status-changed', _handleMediaStatusChanged);

      participant?.off('video-quality-changed', _handleVideoQualityChanged);

      eventEmitter.off(
        events['participant-mode-changed'],
        _handleParticipantModeChanged
      );
    };
  }, [participant]);

  return {
    displayName: participant?.displayName,
    participant,
    webcamStream,
    micStream,
    screenShareStream,
    screenShareAudioStream,
    webcamOn: webcamOn || !!webcamStream,
    micOn: micOn || !!micStream,
    mode: mode,
    metaData: participant?.metaData,

    screenShareOn: !!screenShareStream,
    isLocal: localParticipant?.id === participantId,
    isActiveSpeaker: activeSpeakerId === participantId,
    isMainParticipant: mainParticipant?.id === participantId,
    pinState,

    //
    consumeMicStreams,
    consumeWebcamStreams,
    stopConsumingMicStreams,
    stopConsumingWebcamStreams,
    setQuality,
    setViewPort,
    enableMic,
    disableMic,
    enableWebcam,
    disableWebcam,
    captureImage,
    pin,
    unpin,
    remove,
    switchTo,
    getAudioStats,
    getVideoStats,
    getShareStats
  };
};

export default useParticipant;
