import { useMeeting } from '../meeting/meetingProviderContextDef';

const useFile = () => {
  const mMeeting = useMeeting();

  const uploadBase64File = async ({ base64Data, token, fileName }) => {
    return await mMeeting.meeting.uploadBase64File({
      base64Data,
      token,
      fileName
    });
  };

  const fetchBase64File = async ({ url, token }) => {
    return await mMeeting.meeting.fetchBase64File({ url, token });
  };

  return { uploadBase64File, fetchBase64File };
};

export default useFile;
